import React from "react"
import styled from "styled-components"
import { v4 } from 'uuid'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import { Section, SectionPageTitle, BreadCrumb, SectionDescription } from "../components/Section"
import CallNow from '../components/CallNow'
import BuildingComponentCard from '../components/BuildingComponentCard'
import BreakpointUp from "../components/Media/BreakpointUp"
import { ArrowLeftIcon } from "../components/Icons"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"

const SectionGrid = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin:0 -15px;    
    ${BreakpointUp.lg`        
        margin:0 -20px;    
    `}
    ${BreakpointUp.xl`        
        margin:0 -30px;    
    `}
`
const SectionGridLeft = styled.div`
    position: relative;
    width: 100%;
    padding:0 15px;
    ${BreakpointUp.lg`        
        padding:0 20px;         
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    `}
    ${BreakpointUp.xl`        
        padding:0 30px;         
        flex: 0 0 75%;
        max-width: 75%;
    `}
`
const SectionGridRight = styled.div`
    position: relative;
    width: 100%;
    padding:0 15px;
    ${BreakpointUp.lg`        
        padding:0 20px;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
    ${BreakpointUp.xl`        
        padding:0 30px;
        flex: 0 0 25%;
        max-width: 25%;
    `}
`

const ListComponent = styled.ul`
   margin:0;
   padding:0;
`
const ListComponentItem = styled.li`
    padding:0;
    margin:0 0 30px;
    ${BreakpointUp.md`	
        margin:0 0 30px;
    `}
    + li{       
        padding:30px 0;
        ${BreakpointUp.md`	
            padding:30px 0;
        `}
    }
    .card-img{
        margin-bottom:15px;
        ${BreakpointUp.md`	
            margin-bottom:0;
        `}
    }

`
const Sidebar = styled.div`
   margin:0;   
   background-color:#fff;
   position:relative;
   z-index:1;
   padding:30px;
   ${BreakpointUp.xl`
    padding:30px 30px 30px 90px;
   `}
`
const SidebarHeading = styled.div`
    ${BreakpointUp.xl`	
        position: absolute;
        top: 45%;
        transform: rotate(-90deg) translateY(-50%);
        left: -60px;
    `}
`
const SidebarTitle = styled.h2`
display: block;
white-space: nowrap;
text-transform:uppercase;
color:#000;
font-size: 24px;
line-height: 34px;
margin-bottom:10px;
${BreakpointUp.xl`
    color:rgba(11,57,155,0.10);
    font-size: 34px;
    line-height: 44px;
    margin-bottom:0;
    letter-spacing: -2px;
`}
`
const ListGroup = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    font-size:14px;
    font-weight:700;
    text-transform: uppercase;
`
const ListGroupItem = styled.li`
    position: relative;
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 4px 0;
    margin:0;
    &:first-child{
        padding-top:0;
    }
    & a{
        color:#000;
        &:hover, &:focus{
            color:#0B619B;
        }
    }   
`
const BuildingComponentDetailPage = ({ data, location }) => {
  const pageData = data.contentfulBuildingComponent
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb textAlign="left">
        <div className="container">
          <Link to='/'>Home / </Link>
          <Link to='/'>About Us / </Link>
          <Link to='/building-components'>Building-Components / </Link>
          <span>{pageData.name}</span>
        </div>
      </BreadCrumb>
      <Section pt="215px" pb="90px" xpt="120px" xpb="60px" bgColor="#F2F4F9" className="circle-right md top-0 overflow-intial">
        <div className="container">
          <SectionGrid>
            <SectionGridLeft>
              <Link to="/building-components" className="btn-sm btn-reverse">
                <PrimaryLinkButton icon={<ArrowLeftIcon />} text="Back to Building Components" />
              </Link>
              <SectionPageTitle mb="20px" textAlign="left">{pageData.name}</SectionPageTitle>
              <SectionDescription textAlign="left">
                <div dangerouslySetInnerHTML={{ __html: pageData.fullDescription.childMarkdownRemark.html }} />
              </SectionDescription>
              <ListComponent>
                {
                  pageData.componentItems.map(item => (
                    <ListComponentItem key={v4()}>
                      <BuildingComponentCard data={item} />
                    </ListComponentItem>
                  ))
                }
              </ListComponent>
            </SectionGridLeft>
            <SectionGridRight>
              <div className="isSticky">
                <Sidebar>
                  <SidebarHeading>
                    <SidebarTitle>All Components</SidebarTitle>
                  </SidebarHeading>
                  <div>
                    <ListGroup>
                      {
                        data.allContentfulBuildingComponent.edges.map(item => (
                          <ListGroupItem key={v4()}>
                            <Link to={item.node.url}>{item.node.name}</Link>
                          </ListGroupItem>
                        ))
                      }
                    </ListGroup>
                  </div>
                </Sidebar>
              </div>
            </SectionGridRight>
          </SectionGrid>
        </div>
      </Section>
      <CallNow />
    </Layout>
  )
}

export default BuildingComponentDetailPage

export const pageQuery = graphql`
  query BuildingComponentDetailPageQuery($id: String!) {
    contentfulBuildingComponent(id: { eq: $id }) {
      metaTitle
      metaDescription
      name
      fullDescription {
        childMarkdownRemark {
          html
        }
      }
      componentItems {
        name
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData
        }
      }
    }
    allContentfulBuildingComponent {
      edges {
        node {
          name
          url
        }
      }
    }
  }
`

