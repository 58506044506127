import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import PrimaryLinkButton from "../Button/PrimaryLinkButton"
import BreakpointUp from "../Media/BreakpointUp"

const CardComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 -15px;
  ${BreakpointUp.lg`	
        margin:0 -20px;
    `}
`

const CardComponentFigure = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.sm`		        
        flex: 0 0 50%;
        max-width: 50%;
    `}
  ${BreakpointUp.lg`	
        padding:0 20px;
	`}
    figure {
    position: relative;
    margin: 0;
  }
`
const CardComponentBody = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.sm`	
        flex: 0 0 50%;
        max-width: 50%;
	`}
  ${BreakpointUp.lg`	
        padding:0 20px;
	`}
`
const CardComponentTitle = styled.div`
  margin: 0 0 10px;
  font-weight: 900;
  color: #000;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const CardComponentText = styled.div`
  margin: 0;
`
const BuildingComponentCard = props => {
  const { data } = props
  return (
    <CardComponent className="card">
      <CardComponentFigure className="card-img">
        <figure>
          {data.cardImage ? (
            <GatsbyImage
              image={data.cardImage.gatsbyImageData}
              alt={data.cardImage.title}
            />
          ) : (
            <GatsbyImage
              image={data.image.gatsbyImageData}
              alt={data.image.title}
            />
          )}
        </figure>
      </CardComponentFigure>
      <CardComponentBody className="card-body">
        <CardComponentTitle className="card-title">
          <Link to={data.url}>{data.name}</Link>
        </CardComponentTitle>
        <CardComponentText>
          {data.cardImage ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data.cardDescription.childMarkdownRemark.html,
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html,
              }}
            />
          )}
        </CardComponentText>
        {data.url ? (
          <Link to={data.url} className="btn-sm">
            <PrimaryLinkButton icon={<RightLineArrowIcon />} text="View All" />
          </Link>
        ) : (
          <></>
        )}
      </CardComponentBody>
    </CardComponent>
  )
}

export default BuildingComponentCard
